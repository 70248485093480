import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { Client, GiftCard, GiftCardAuthorizationHold, GiftCardLogResponse, GiftCardProduct, GiftCardSettingsStub, MyHttpApi, ParamsType } from 'utils/api';
import { validEmail } from 'utils/email-utils';
import { Notify } from 'utils/notify';

interface UIGiftCardAuthorizationHold extends GiftCardAuthorizationHold {
  billId?: string;
}

interface UIGiftCardLog extends GiftCardLogResponse {
  billId?: string;
}


@autoinject
export class GiftCardsShow {
  private giftCard?: GiftCard;
  private giftCardSettings?: GiftCardSettingsStub;
  private logList: UIGiftCardLog[] = [];
  private authorizationHoldList: UIGiftCardAuthorizationHold[] = [];
  private productList: GiftCardProduct[] = [];
  private client?: Client;
  private clients: { [key: number]: Client; } = {};
  private product?: GiftCardProduct;
  private editMode = false;

  private logFieldList: FieldSpec[] = [
    { key: "eventTime", header: "common.time", type: "date", cssClass: "not-clickable" },
    { key: "eventType", header: "giftCard.event", type: "enum", enum: "GiftCardEvent", cssClass: "not-clickable" },
    { key: "system", header: "app.system", type: "enum", enum: "GiftCardSystem", cssClass: "not-clickable" },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: this.clients, cssClass: "not-clickable", lookupKey: "name", },
    { key: "actorName", header: "giftCard.actor", type: "text", cssClass: "not-clickable" },
    { key: "businessDate", header: "giftCard.useDate", type: "date", cssClass: "not-clickable", format: "DD.MM.YYYY" },
    { key: "billId", header: "giftCard.billId", type: "text", cssClass: "not-clickable" },
    { key: "authorizationHoldId", header: "giftCard.useId", type: "text", cssClass: "not-clickable", },
    { key: "value", header: "common.value", type: "number", minimumFractionDigits: 2, cssClass: "not-clickable" },
    { key: "message", header: "giftCard.message", type: "text", cssClass: "not-clickable" },
  ];

  private authorizationHoldFieldList: FieldSpec[] = [
    { key: "id", header: "#", type: "text", cssClass: "not-clickable" },
    { key: "createTime", header: "common.time", type: "date", cssClass: "not-clickable" },
    { key: "system", header: "app.system", type: "enum", enum: "GiftCardSystem", cssClass: "not-clickable" },
    { key: "billId", header: "giftCard.billId", type: "text", cssClass: "not-clickable" },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: this.clients, cssClass: "not-clickable", lookupKey: "name", },
    { key: "value", header: "common.value", type: "number", minimumFractionDigits: 2, cssClass: "not-clickable" },
  ];

  constructor(private api: MyHttpApi, private router: Router, private notify: Notify) {
  }

  async activate(params: ParamsType) {
    let id: number = parseInt(params.id);
    [this.giftCard, this.giftCardSettings, this.authorizationHoldList] = await Promise.all([
      this.api.giftCardById({ id }),
      this.api.giftCardSettingsByGiftCardId({ id }),
      this.api.giftCardAuthorizationHoldList({ id }),
    ]);
    this.authorizationHoldList = this.authorizationHoldList.map(gcah => {
      return {...gcah, billId: this.reference2billId(gcah.reference)};
    });
    if (this.giftCard) {
      let [client, clientList, productList] = await Promise.all([
        this.api.clientById({ id: this.giftCard.purchasedAtClientId }),
        this.api.clientList(),
        this.api.giftCardProductList({ businessGroupId: this.giftCardSettings.businessGroupId, undeletedOnly: false }),
      ]);
      this.client = client;
      this.productList = productList;
      for (let c of clientList) {
        this.clients[c.id] = c;
      }
    }
    await this.refresh();
  }

  reference2billId(reference: string) {
    const matches = reference.match(/^(#\d+),/);
    if (matches?.length) {
      return matches[1];
    }
  }

  async refresh() {
    if (this.giftCard?.id) {
      const logList = await this.api.giftCardLogList({ id: this.giftCard.id });
      this.logList = logList.map(l => {
        return {...l, billId: this.reference2billId(l.reference)}
      });
      if(this.giftCard.productId) {
        this.product = await this.api.giftCardProductById({ id: this.giftCard.productId });
      }
    }
  }

  @computedFrom("giftCard.purchaserEmail", "logList")
  get canSendEmail() {
    if (!this.giftCard?.id) {
      return false;
    }
    if (!validEmail(this.giftCard.purchaserEmail)) {
      return false;
    }
    if (this.logList.find(
      l => ["EXPIRE", "REFUND"].includes(l.eventType)
    )) {
      return false;
    }
    return true;
  }

  async sendEmail() {
    if (!this.giftCard?.id) {
      return;
    }
    await this.api.giftCardSendEmail({ id: this.giftCard.id });
    this.notify.info("giftCard.emailSent");
    await this.refresh();
  }

  async downloadPdf() {
    if (!this.giftCard?.id) {
      return;
    }
    const pdfUrl = this.api.giftCardRenderPdfUrl({ gcId: this.giftCard.id });
    window.open(pdfUrl, "_blank");
    await this.refresh();

  }

  @computedFrom("logList", "authorizationHoldList")
  get valueLeft() {
    let accountedValue = this.logList.map(l => l.value).reduce((a, b) => a + b, 0);
    let unaccountedAuthorizationHoldValue = this.authorizationHoldList.map(ah => ah.value).reduce((a, b) => a + b, 0);
    return accountedValue - unaccountedAuthorizationHoldValue;
  }

  async toggleEditMode() {
    if (!this.giftCard) {
      return;
    }
    let request = {
      id: this.giftCard.id,
      notAfter: this.giftCard.notAfter,
      purchaserEmail: this.giftCard.purchaserEmail,
      productName: this.giftCard.productName,
      productId: this.giftCard.productId,
      freeText: this.giftCard.freeText || undefined,
    };
    if (this.editMode) {
      await this.api.giftCardUpdate(request)
      this.notify.info("common.updateDone");
      await this.refresh();
    }
    this.editMode = !this.editMode;
  }

  @computedFrom("editMode")
  get productListDynamic() {
    if (!this.editMode) {
      return this.productList;
    }
    return this.productList.filter(p => !p.deleteTime);
  }
}
