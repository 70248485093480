import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, GiftCardListResponse, GiftCardProduct, GiftCardSystem, IdName, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class GiftCardsList extends BaseRoute {
  private clientList: Client[] = [];
  private businessGroupList: BusinessGroup[] = [];
  private businessGroups: { [key: number]: BusinessGroup; } = {};
  private clients: { [key: number]: IdName; } = {};
  private products: { [key: number]: GiftCardProduct; } = {};
  private systemList: { id: string, name: string; }[] = [
    { id: "POS", name: this.i18n.tr("GiftCardSystem.POS") },
    { id: "DASHBOARD", name: this.i18n.tr("GiftCardSystem.DASHBOARD") },
    { id: "NETSTORE", name: this.i18n.tr("GiftCardSystem.NETSTORE") },
  ];

  private fieldList: FieldSpec[] = [
    { key: "number", header: "giftCard.number", type: "number", sortType: "number" },
    { key: "purchasedAtSystem", header: "giftCard.purchasedAtSystem", type: "enum", enum: "GiftCardSystem" },
    { key: "createTime", header: "news.createTime", type: "date" },
    { key: "notAfter", header: "giftCard.expiryDate", type: "date", format: "DD.MM.YYYY" },
    { key: "businessGroupId", header: "giftCard.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "purchasedAtClientId", header: "unit.unit", type: "lookup", lookupData: this.clients, lookupKey: "name", },
    { key: "productName", header: "giftCard.productNameText", type: "text" },
    { key: "productId", header: "giftCard.productName", type: "lookup", lookupData: this.products, lookupKey: "productName" },
    { key: "purchaserEmail", header: "emailTemplate.GiftCardEmailDeliveryPurchaserEmail", type: "text" },
    { key: "name", header: "giftCard.name", type: "text" },
    { key: "purchaseValue", header: "giftCard.purchaseValue", type: "number", minimumFractionDigits: 2 },
    { key: "valueLeft", header: "giftCard.valueLeft", type: "number", minimumFractionDigits: 2 },
    { key: "purchasedAtReference", header: "common.reference", type: "text" },
    { key: "physical", header: "giftCard.physical", type: "boolean" },
  ];

  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.giftCardList(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  private giftCardList: GiftCardListResponse[] = [];

  @observable({ changeHandler: "search" })
  private businessGroupId?: number;
  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private system?: GiftCardSystem;
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private number?: string;
  @observable({ changeHandler: "search" })
  private minSum?: number;
  @observable({ changeHandler: "search" })
  private maxSum?: number;
  @observable({ changeHandler: "search" })
  private productName?: string;
  @observable({ changeHandler: "search" })
  private name?: string;
  @observable({ changeHandler: "search" })
  private email?: string;
  @observable({ changeHandler: "search" })
  private reference?: string;

  protected override routeParams() {
    return {
      "clientId": Number,
      "businessGroupId": Number,
      "system": String,
      "startDate": Date,
      "endDate": Date,
      "number": String,
      "minSum": Number,
      "maxSum": Number,
      "productName": String,
      "name": String,
      "email": String,
      "reference": String,
    };
  }

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N) {
    super();
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    let [clientList, businessGroupList, productList] = await Promise.all([
      this.api.clientList(),
      this.api.businessGroupList(),
      this.api.giftCardProductList({ undeletedOnly: false, }),
    ]);
    for (let c of clientList) {
      this.clients[c.id] = { id: c.id, name: c.nickname };
    }
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    for (let p of productList) {
      this.products[p.id] = p;
    }
    this.clientList = clientList;
    this.businessGroupList = businessGroupList;
    await this.ecs.search();
  }

  buildQueryParams() {
    return {
      clientId: this.clientId,
      businessGroupId: this.businessGroupId,
      system: this.system,
      startDate: this.startDate,
      endDate: this.endDate,
      number: this.number,
      minSum: this.minSum,
      maxSum: this.maxSum,
      productName: this.productName,
      name: this.name,
      email: this.email,
      reference: this.reference,
    };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: GiftCardListResponse[]) {
    this.giftCardList = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  async rowCall(key: string, row: GiftCardListResponse) {
    if (row.id) {
      this.router.navigateToRoute("gift-cards/show", { id: "" + row.id });
    }
  }

  @computedFrom("giftCardList")
  get giftCardTotal() {
    return this.giftCardList.map(p => p.valueLeft).reduce((a, b) => a + b, 0);
  }

  @computedFrom("businessGroupId")
  get clientListDynamic() {
    if (this.businessGroupId) {
      return this.clientList.filter(x => x.businessGroupId === this.businessGroupId);
    }
    return this.clientList.filter(x => x.businessGroupId);
  }
}
