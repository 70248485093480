import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, EmailTemplate, MyHttpApi } from 'utils/api';

@autoinject
export class GiftCardsEmailTemplatesList {
  private list: EmailTemplate[] = [];
  private businessGroups: { [key: number]: BusinessGroup } = {};

  private fieldList: FieldSpec[] = [
    { key: "businessGroupId", header: "emailTemplate.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "language", header: "emailTemplate.language", type: "enum", enum: "Language" },
    { key: "subject", header: "emailTemplate.subject", type: "text" },
  ];

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate() {
    let businessGroupList = await this.api.businessGroupList();
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    await this.refresh();
  }

  async refresh() {
    this.list = await this.api.emailTemplateListGiftCard();
  }

  select(et: EmailTemplate) {
    this.router.navigateToRoute("gift-cards/email-templates/edit", { id: et.id });
  }

  @computedFrom("businessGroups")
  get showCreateLink() {
    return Object.keys(this.businessGroups).length > 0;
  }
}
