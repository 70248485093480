import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, GiftCardProduct, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class GiftCardsUpload {
  private businessGroupList: BusinessGroup[] = [];
  private clientList: Client[] = [];
  private giftCardProductList: GiftCardProduct[] = [];
  private businessGroupId?: number;
  private clientId?: number;
  private giftCardProductId?: number;
  private files?: FileList;

  constructor(private api: MyHttpApi, private router: Router, private notify: Notify, private i18n: I18N) {
  }

  async activate() {
    [this.businessGroupList, this.clientList, this.giftCardProductList] = await Promise.all([
      this.api.businessGroupList(),
      this.api.clientList(),
      this.api.giftCardProductList({undeletedOnly: true}),
    ]);
  }

  @computedFrom("businessGroupId")
  get dynamicClientList() {
    return this.clientList.filter(c => c.businessGroupId === this.businessGroupId);
  }

  @computedFrom("businessGroupId")
  get dynamicGiftCardProductList() {
    return this.giftCardProductList.filter(gcp => gcp.businessGroupId === this.businessGroupId);
  }

  async uploadFile() {
    if (!this.files || !this.clientId || !this.businessGroupId || !this.giftCardProductId) {
      return;
    }

    let reader = new FileReader();
    reader.addEventListener("load", async e => {
      if (typeof reader.result === "string") {
        let file = reader.result.replace(/^[^,]+,/, "");
        await this.api.giftCardExcelUpload({
          clientId: this.clientId!,
          businessGroupId: this.businessGroupId!,
          giftCardProductId: this.giftCardProductId!,
          file,
        });
        this.notify.info("common.saved");
        this.router.navigateToRoute("gift-cards/list");
      }
    });
    reader.readAsDataURL(this.files[0]);
  }
}
