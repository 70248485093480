import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, EmailPurpose, EmailTemplate, GiftCardProductUpdateRequest, GiftCardSettingsStub, MyHttpApi } from 'utils/api';


@autoinject
export class GiftCardsProductsEdit {
  private settingsList: GiftCardSettingsStub[] = [];
  private emailTemplatesList: EmailTemplate[] = [];
  private businessGroupList: BusinessGroup[] = [];
  private clientList: Client[] = [];

  private product: GiftCardProductUpdateRequest = {
    settingsId: 0,
    businessGroupId: 0,
    emailTemplateIdFi: 0,
    productName: "",
    openPrice: false,
    openFreeText: false,
    groupCards: false,
    delete: false,
    price: 0,
  };
  pdfUrl = "";

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    [this.settingsList, this.emailTemplatesList, this.businessGroupList, this.clientList] = await Promise.all([
      this.api.giftCardSettingsList(),
      this.api.emailTemplateListGiftCard(),
      this.api.businessGroupList(),
      this.api.clientList(),
    ]);
    if (params.id) {
      const id = parseInt(params.id);
      let tmp = await this.api.giftCardProductById({ id });
      this.product = { ...tmp, delete: false };
    }
  }

  @computedFrom("product.settingsId")
  get settingsListener() {
    if (this.product.settingsId) {
      this.pdfUrl = ""
      this.api.giftCardSettingsPdfById({ id: this.product.settingsId })
        .then(res => this.pdfUrl = "data:application/pdf;base64," + res.pdf);
    }
    return undefined;
  }

  @computedFrom("product.businessGroupId")
  get dynamicSettingsList() {
    return this.settingsList.filter(gcs => gcs.businessGroupId === this.product.businessGroupId);
  }

  @computedFrom("product.businessGroupId", "emailPurpose")
  get dynamicEmailTemplatesFiList() {
    return this.emailTemplatesList.filter(et => et.businessGroupId === this.product.businessGroupId && et.language === 'FI' && et.purpose === this.emailPurpose);
  }

  @computedFrom("product.businessGroupId", "emailPurpose")
  get dynamicEmailTemplatesEnList() {
    return this.emailTemplatesList.filter(et => et.businessGroupId === this.product.businessGroupId && et.language === 'EN' && et.purpose === this.emailPurpose);
  }

  @computedFrom("product.groupCards")
  get emailPurpose(): EmailPurpose {
    return this.product.groupCards ? 'GroupedGiftCardEmailDelivery' : 'GiftCardEmailDelivery';
  }

  async save(deleted: boolean) {
    await this.api.giftCardProductUpdate({ ...this.product, delete: deleted });
    this.router.navigateToRoute('gift-cards/products/list');
  }

  @computedFrom("product.settingsId")
  get settings() {
    if (!this.product.settingsId) {
      return undefined;
    }
    return this.settingsList.find(gcs => gcs.id === this.product.settingsId);
  }

  @computedFrom("product.businessGroupId")
  get businessGroup() {
    return this.businessGroupList.find(bg => bg.id === this.product.businessGroupId);
  }

  @computedFrom("product.businessGroupId")
  get dynamicClientList() {
    const list = this.clientList.filter(client => client.businessGroupId === this.product.businessGroupId);
    if (!list.find(x => x.id === this.product.clientId)) {
      this.product.clientId = undefined;
    }
    return list;
  }
}
