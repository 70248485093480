import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, GiftCardProduct, GiftCardSettings, GiftCardSettingsStub, MyHttpApi, ParamsType } from 'utils/api';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';

@autoinject
export class GiftCardsProductList extends BaseRoute {
  private businessGroupList: BusinessGroup[] = [];
  private businessGroups: { [key: number]: BusinessGroup; } = {};
  private clientList: Client[] = [];
  private clients: { [key: number]: Client; } = {};
  private settingsList: GiftCardSettingsStub[] = [];
  private settings: { [key: number]: GiftCardSettingsStub; } = {};
  private productList: GiftCardProduct[] = [];

  private fieldList: FieldSpec[] = [
    { key: "businessGroupId", header: "giftCard.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "clientId", header: "unit.unit", type: "lookup", lookupData: this.clients, lookupKey: "nickname", },
    { key: "settingsId", header: "giftCard.giftCardTemplates", type: "lookup", lookupData: this.settings, lookupKey: "name", },
    { key: "productName", header: "giftCard.name", type: "text" },
    { key: "price", header: "common.price", type: "number", minimumFractionDigits: 2 },
    { key: "openPrice", header: "giftCard.openPrice", type: "boolean", },
    { key: "groupCards", header: "giftCard.groupCards", type: "boolean", },
    { key: "validityDays", header: "giftCard.validityDays", type: "number" },
    { key: "notAfter", header: "giftCard.notAfterDate", type: "date", format: 'DD.MM.YYYY' },
  ];

  // ECS to handle possible urlQueryParam race
  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.giftCardProductList({ ...args, undeletedOnly: true }),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  @observable({ changeHandler: "search" })
  private businessGroupId?: number;
  @observable({ changeHandler: "search" })
  private settingsId?: number;
  @observable({ changeHandler: "search" })
  private minSum?: number;
  @observable({ changeHandler: "search" })
  private maxSum?: number;
  @observable({ changeHandler: "search" })
  private productName?: string;
  @observable({ changeHandler: "search" })
  private clientId?: number;

  protected override routeParams() {
    return {
      "businessGroupId": Number,
      "clientId": Number,
      "settingsId": Number,
      "minSum": Number,
      "maxSum": Number,
      "productName": String,
    };
  }

  constructor(private api: MyHttpApi, private router: Router) {
    super();
  }

  override async activate(params: ParamsType) {
    super.activate(params);
    [this.businessGroupList, this.settingsList, this.clientList] = await Promise.all([
      this.api.businessGroupList(),
      this.api.giftCardSettingsList(),
      this.api.clientList(),
    ]);
    for (let bg of this.businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    for (let c of this.clientList) {
      this.clients[c.id] = c;
    }
    for (let s of this.settingsList) {
      this.settings[s.id] = s;
    }
    await this.search();
  }

  buildQueryParams() {
    return {
      businessGroupId: this.businessGroupId,
      settingsId: this.settingsId,
      minSum: this.minSum,
      maxSum: this.maxSum,
      productName: this.productName,
      clientId: this.clientId,
    };
  }

  async search() {
    await this.ecs.search();
  }

  applyData(data: GiftCardProduct[]) {
    this.productList = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  rowCall(value: GiftCardSettings) {
    this.router.navigateToRoute("gift-cards/products/edit", { id: value.id });
  }

  @computedFrom("businessGroupId", "clientId")
  get settingsListDynamic() {
    let bgId: number  | undefined = this.businessGroupId;
    if (this.clientId) {
      const client = this.clientList.find(x => x.id === this.clientId);
      bgId = client?.businessGroupId;
    }
    const list = this.settingsList.filter(s => !bgId || s.businessGroupId === bgId);
    // Empty current selection from settingsId, if it is not in the new list
    if (!list.find(x => x.id === this.settingsId)) {
      this.settingsId = undefined;
    }
    return list;
  }

  @computedFrom("businessGroupId")
  get clientListDynamic() {
    const list = this.clientList.filter(client => !this.businessGroupId || client.businessGroupId === this.businessGroupId);
    if (!list.find(x => x.id === this.clientId)) {
      this.clientId = undefined;
    }
    return list;
  }
}
