import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessGroup, EmailTemplateUpdateRequest, MyHttpApi } from 'utils/api';

@autoinject
export class GiftCardsEmailTemplatesEdit {
  private businessGroupList: BusinessGroup[] = [];

  private email: EmailTemplateUpdateRequest = {
    purpose: "GiftCardEmailDelivery",
    language: "FI",
    subject: "",
    text: "<p></p>",
    delete: false,
  };
  private groupGiftCards = false;

  constructor(private router: Router, private api: MyHttpApi) {
  }

  async activate(params: { id?: string }) {
    this.businessGroupList = await this.api.businessGroupList();
    if (params.id) {
      let tmp = await this.api.emailTemplateById({ id: parseInt(params.id), asUri: true });
      this.email = {...tmp, delete: false};
			this.groupGiftCards = this.email.purpose === "GroupedGiftCardEmailDelivery";
    }
  }

	setPurpose() {
		this.email.purpose = this.groupGiftCards ? "GroupedGiftCardEmailDelivery" : "GiftCardEmailDelivery";
	}

  async save(deleted: boolean) {
    if (!this.email) {
      return;
    }
    await this.api.emailTemplateUpdate({
      ...this.email,
      delete: deleted,
    });
    this.router.navigateToRoute("gift-cards/email-templates/list");
  }
}
