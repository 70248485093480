import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi } from './utils/api';

@autoinject
export class Empty {
  constructor(private api: MyHttpApi, private router: Router) {
  }

activate() {
    if (this.api.session?.canAdmin) {
      this.router.navigateToRoute("admin/clients/list");
    } else {
      this.router.navigateToRoute("news/index");
    }
  }
}
