// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#product-edit {
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr;
}
iframe {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/gift-cards/products/gift-card-products.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,mCAAA;AADF;AAIA;EACE,WAAA;EACA,YAAA;AAFF","sourcesContent":["@import \"styles/variables.less\";\n\n#product-edit {\n  display: grid;\n  height: 100%;\n  grid-template-rows: max-content 1fr;\n}\n\niframe {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
