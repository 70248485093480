import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, GiftCardSettingsStub, MyHttpApi } from 'utils/api';

@autoinject
export class GiftCardsSettingsList {
  private businessGroups: { [key: number]: BusinessGroup } = {};

  private fieldList: FieldSpec[] = [
    { key: "id", header: "#", type: "text", },
    { key: "businessGroupId", header: "giftCard.businessGroup", type: "lookup", lookupData: this.businessGroups, lookupKey: "name", },
    { key: "name", header: "giftCard.name", type: "text" },
  ];

  private settingsList: GiftCardSettingsStub[] = [];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    let businessGroupList = await this.api.businessGroupList();
    for (let bg of businessGroupList) {
      this.businessGroups[bg.id] = bg;
    }
    this.settingsList = await this.api.giftCardSettingsList();
  }

  rowCall(value: GiftCardSettingsStub) {
    this.router.navigateToRoute("gift-cards/settings/edit", { id: value.id });
  }
}
